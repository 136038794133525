import React from 'react';
import { Link, graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

interface Props {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          excerpt: string;
          fields: {
            readingTime: {
              text: string;
            };
            slug: string;
          };
          frontmatter: {
            date: string;
            description: string;
            title: string;
            featuredImage: {
              childImageSharp: {
                sizes: FluidObject;
              };
            };
          };
        };
      }[];
    };
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: {
    pathname: string;
  };
}

const BlogIndex = ({ data, location }: Props) => {
  const siteTitle = data.site.siteMetadata.title;
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <div className="outer">
        <div className="inner">
          <h1>All Blog Posts</h1>
          <div className="post-feed">
            {posts.map(({ node }) => {
              const title = node.frontmatter.title || node.fields.slug;
              return (
                <article
                  className="post-card post post-card-large"
                  key={node.fields.slug}
                >
                  <Link to={node.fields.slug} className="post-card-image-link">
                    <Img
                      className="post-card-image"
                      sizes={
                        node.frontmatter.featuredImage.childImageSharp.sizes
                      }
                    />
                  </Link>
                  <div className="post-card-content">
                    <a
                      className="post-card-content-link"
                      href={node.fields.slug}
                    >
                      <header className="post-card-header">
                        {/* FIXME: Make this some part of the metadata: tags? */}
                        <span className="post-card-tags">Blog Entry</span>
                        <h2 className="post-card-title">{title}</h2>
                        <small>{node.frontmatter.date}</small>
                      </header>
                      <section className="post-card-excerpt">
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              node.frontmatter.description || node.excerpt,
                          }}
                        />
                      </section>
                    </a>
                    <footer className="post-card-meta">
                      {/* FIXME: figure out what we want to put here */}
                      <div className="author-list" />
                      <span className="reading-time">
                        {node.fields.readingTime.text}
                      </span>
                    </footer>
                  </div>
                </article>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            readingTime {
              text
            }
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            description
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            title
          }
        }
      }
    }
  }
`;
